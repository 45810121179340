import ProfileContent from "../components/ProfilePage/ProfileContent"

const Profile = () => {
    return (
        <div>
            <ProfileContent />
        </div>
    )
}

export default Profile