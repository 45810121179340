import LoginForm from '../components/forms/loginForm';

const Login = () => {
  return (
    <div>
    <LoginForm/>
    </div>
  )
}

export default Login
